@import "variables.less";
@import "global-vars";
@import "mixins";

html, body {
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  font-family: Roboto,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: @body-color;
  background-color: #fff;
}

body:not(.login-page) {
  background: @lightgray;
}

label {
  line-height: 1.5;
  font-size: 1rem;
}

.height-100 {
  height: 100%;
}

.date-range .form-control {
  display: inline-block;
  width: 100px;
  font-size: 12px;
  float: left;
  border-radius: 0;
  height: 34px;
  position: relative;
}

.date-range .form-control:not(:first-child) {
  margin-left: -2px;
}

.date-range .form-control:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.date-range .form-control:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.panel .date-range .form-control {
  width: 100%;
}

.panel .date-range .form-control {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 0 !important;
}

.panel .date-range .form-control:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 6px !important;
  margin-top: -2px;
}

.date-range .form-control:focus {
  z-index: 5;
}

.loading {
  position: absolute;
  width: 50%;
  z-index: 5;
  top: 50%;
  left: 50%;
  margin-left: -25%;
  height: 20px;
  margin-top: -10px;
  margin-bottom: 0;
}

.loading.loading-text {
  position: relative;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
}

.pos-relative {
  position: relative;
}

.vis-hidden {
  visibility: hidden;
  opacity: 0;
  transition: 400ms;
}

.text-center p {
  text-align: center;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pagination {
  margin-top: 0;
  margin-bottom: 0;
  float: left;
  width: 86%;
  ul {
    background-color: transparent;
    &.dropdown-menu {
      li {
        float: none;
        margin: 0;
      }
    }
    li {
      float: left;
      margin-right: 5px;
      &.pagination-dropdown {
        float: right;
        margin-right: 0;
      }
      &.disabled {
        span {
          background: none !important;
          border-color: #ddd !important;
          color: #ddd !important;
        }
      }
      &.previous a:before {
        padding-right: 5px;
        font-size: 12px;
      }
      &.next a:before {
        padding-left: 5px;
        font-size: 12px;
      }
      span {
        &.break {
          color: @gray;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

textarea {
  resize: none;
}

.tooltip {
  z-index: 1150;
}

.tooltip.tooltip--danger {

  .tooltip-inner {
    background-color: @color_danger;
  }

  &.right .tooltip-arrow {
    border-right-color: @color_danger;
  }

  &.left .tooltip-arrow {
    border-left-color: @color_danger;
  }

  &.top .tooltip-arrow {
    border-top-color: @color_danger;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: @color_danger;
  }
}

.tooltip-inner {
  width: auto;
  max-width: 183px;
  font-size: 11px;
  padding: 5px 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25)
}

.table-condensed thead > tr > th, .table-condensed tbody > tr > th, .table-condensed tfoot > tr > th, .table-condensed thead > tr > td, .table-condensed tbody > tr > td, .table-condensed tfoot > tr > td {
  padding: 7px;
  vertical-align: middle;
}

.dropdown-inverse li > a {
  font-size: 12px;
}

.btn-group > .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.modal-dialog {

  .modal-content {
    -webkit-box-shadow: 0px 0px 50px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 50px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 50px 0 rgba(0, 0, 0, 0.35);

    .modal-body {
      background-color: white;
      max-height: calc(~"100vh - 203px");
      overflow-y: auto;
    }

  }
}

.modal-title {
  font-weight: normal;
}

.table-no-bordered > thead > tr th, .table-no-bordered > tbody > tr th, .table-no-bordered > tfoot > tr th, .table-no-bordered > thead > tr td, .table-no-bordered > tbody > tr td, .table-no-bordered > tfoot > tr td {
  border: none !important;
  padding: 0;
}

.no-wrap {
  white-space: nowrap;
}

.table > thead > tr > th {
  background-color: @gray;
  color: white;
}

.table.table-condensed {
  font-size: 14px;
}

.dropdown-menu:not(.dropdown-inverse) {
  background-color: @color1_light;
  color: white;
}

.dropdown-arrow:not(.dropdown-arrow-inverse) {
  border-color: transparent transparent @color1_light transparent;
}

.dropdown-menu:not(.dropdown-inverse) li > a {
  color: white;
  border-bottom: 2px solid @color1;
}

.dropdown-menu:not(.dropdown-inverse) li > a:hover {
  background-color: @color1;
}

.dropdown-menu:not(.dropdown-inverse) li:first-child > a {
  border-radius: 4px 4px 0 0;
}

.dropdown-menu:not(.dropdown-inverse) li:last-child > a {
  border-radius: 0 0 4px 4px;
  border-bottom: none;
}

.menu-disabled {
  color: gray !important;
  cursor: default;
}

.img-circle {
  border-radius: 50% !important;
}

.has-success {
  .form-control {
    color: @text_color;
    border-color: @color_success;
  }
}

.has-warning {
  .form-control {
    color: @text_color;
  }
}

.text-success {
  color: @color_success !important;
}

.text-danger {
  color: @color_danger !important;
}

.box {
  background-color: white;
  border-radius: 6px !important;
  border: 2px solid @lightgray_dark;
  overflow: hidden;
  margin: 0 auto;
  padding: 40px;
  margin-bottom: 5%;
  margin-top: 5%;
  display: inline-block;
  .input-label {
    float: left;
    color: @color2;
    text-transform: uppercase;
    margin-top: 20px;
    &:last-of-type {
      margin-top: 30px;
    }
    &.alt {
      width: 100%;
      text-transform: none;
      text-align: center;
    }
  }

  input {
    border-radius: 5px !important;
    border: solid 1px #e0e0e0;
    font-size: 13px;
  }

  .back-to-login {
    display: inline-block;
    padding-top: 20px;
    max-width: 322px;
    text-align: center;
  }
}

/* Button classes: no bg, no bg dropdowns and pgination */
.btn-no-bg, .btn-no-bg-drop, .btn-paging {
  background: none !important;
  border-radius: 20px !important;
  &.active {
    border-color: @color1;
    color: @color1;
  }

  & + .dropdown-backdrop {
    display: none;
  }
}

/* Mixins */
.no-bg-hover {
  background: @color1 !important;
  border: solid 1px transparent;
  color: #fff;
}

.no-bg-hover-alt {
  background: none !important;
  color: @color1 !important;
  border-color: @color1 !important;
}

.btn-no-bg {
  color: @color1;
  border: solid 1px @color1;
  &:active, &:focus {
    outline: none !important;
    border-color: @color1 !important;
    color: @color1;
  }
  &:hover {
    .no-bg-hover;
  }
}

.btn-no-bg-drop, .btn-paging {
  color: @gray;
  border: solid 1px @gray;
  &:active, &:hover, &:focus {
    .no-bg-hover-alt;
  }
}

.btn-group.open .dropdown-toggle {
  &.btn-no-bg {
    .no-bg-hover;
  }
  &.btn-no-bg-drop {
    .no-bg-hover-alt;
  }
}

/* Pagination */
li.active .btn-paging {
  .no-bg-hover-alt;
}

.btn-no-bg-drop.fui-arrow-right {
  &:before {
    float: right;
  }
}

/* Generic button */
.btn-caret {
  color: @gray;
  border: solid 1px @gray;
  background: none;
  span {
    &.filter-option {
      left: 12px !important;
      color: @text_color;
    }
    &.caret {
      top: 1px !important;
      bottom: 0;
      right: 16px !important;
      height: 21px;
      margin: auto;
      border: none !important;
      transition: none;
      &:hover {
        background-color: transparent;
      }
      &:before {
        content: "\f078";
        font-family: 'FontAwesome';
        font-size: 10px;
      }
    }
  }
}

/* No radius */
.no-right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-left: 1px !important;
}

.no-left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}


/* Tabs */
.nav-tabs {
  border-width: 1px;
  li {
    a {
      text-transform: uppercase;
      margin-right: 30px;
      padding: 7px 10px 8px;
      font-size: 12px;

    }
    &.active a,
    &.active a:hover,
    &.active a:focus {
      border: none;
      border-bottom: solid 1px @color2;
      padding-bottom: 9.5px;
      color: @color1_dark;
      font-weight: bold;
    }
  }
}

.form-control {
  border-width: 1px !important;
}

.input-group-btn:last-child > .btn {
    margin-left: -1px;
}

.filters {
  label.not-first-row {
    margin-top: 15px;
  }

  .filter-action-buttons {
    display: inline-block;

    .btn {
      margin-top: 17px;
      font-size: 12px;
      outline: none;

      &.btn-primary {
        border-radius: 20px;
        text-transform: uppercase;
        padding: 7px 20px;
      }

      &.btn-link {
        color: @color2;
        text-decoration: none;
      }
    }

    &.not-first-row {
      .btn {
        margin-top: 31px;
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-xlg {
    width: 700px;
  }
}

@media (min-width: 1382px) {
  .modal-xlg {
    width: 1000px;
  }
}

@media (min-width: 992px) {
  .row {
    .col-separator {
      border-left: 1px solid @lightgray_dark;
      border-right: 1px solid @lightgray_dark;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }

      &:not(:first-child) {
        margin-left: -1px;
      }
    }
  }
}

::-webkit-input-placeholder { font-style: italic; }
::-moz-placeholder { font-style: italic; } /* firefox 19+ */
:-ms-input-placeholder { font-style: italic; } /* ie */
input:-moz-placeholder { font-style: italic; }
.tooltip {
  ul {
    padding: 0;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.date-picker {
  border-color: @gray !important;
  border-radius: 20px !important;
  opacity: 1 !important;

  &:hover {
    border-color: @color1 !important;
  }
}

.date-range {
  .date-picker {
    &:first-child {
      border-radius: 20px 0 0 20px !important;
    }

    &:last-child {
      border-radius: 0 20px 20px 0 !important;
    }
  }
}

.tooltip-inner ul {
  padding-left: 15px;
  text-align: left;
  margin-bottom: 0;
}

::-webkit-input-placeholder { font-style: italic; }
::-moz-placeholder { font-style: italic; } /* firefox 19+ */
:-ms-input-placeholder { font-style: italic; } /* ie */
input:-moz-placeholder { font-style: italic; }

.alert {
  color: @text_color;
  padding: 15px;

  &.alert-dismissible {
    padding-right: 50px;
  }

  &.alert-success, &.alert-danger, &.alert-warning {
    color: white;

    .close {
      color: white;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }
    }

    .text-muted {
      color: #ddd;
    }

    a {
      color: white;
    }
  }

  &.alert-success {
    background-color: @alert-success-bg;
    border-color: #779d64;
  }

  &.alert-danger {
    background-color: #D64F68;
    border-color: #a53f52;
  }

  &.alert-warning {
    background-color: #EFA563;
    border-color: #F0872A;
  }

  a {
    color: @text_color;
    text-decoration: underline;
    font-weight: normal;

    &:hover, &:focus, &:active {
      color: @color1;
    }
  }
}

.ui-datepicker {
  border: 1px solid @lightgray_dark;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  z-index: 11 !important;

  &:before {
    top: -7px;
  }

  .ui-datepicker-header {
    margin: -1px -1px 2px;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    border: none;
  }

  .ui-state-default {
    border-radius: 0;
  }

  .ui-state-active {
    &:hover {
      color: white;
    }
  }
}

.material-icons,
.material-icons-outlined {
  vertical-align: middle;

  &.md-18 { 
    font-size: 18px;
    max-width: 18px;
  }
  &.md-24 { 
    font-size: 24px;
    max-width: 24px;
  }
  &.md-36 { 
    font-size: 36px;
    max-width: 36px;
  }
  &.md-48 {
    font-size: 48px;
    max-width: 48px;
  }

  &.md-dark { color: rgba(0, 0, 0, 0.54); }
  &.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

  &.md-light { color: rgba(255, 255, 255, 1); }
  &.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
}

.popover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

#webWidget {
  // ((window width - container width) by 2) plus 30px for nice margin
  margin-left: calc(~"((100% - 1260px) / 2) + 30px") !important;
  margin-bottom: 40px;
}
