.ec label {
  line-height: 1.5;
  font-size: 1rem;
}
.ec p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.ec .ui-datepicker:before {
  border-color: transparent transparent transparent;
}
.ec .ec-form-control::placeholder {
  font-style: normal;
}
