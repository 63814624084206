#content {
  padding: 1.5rem;
  background-color: white;
  display: table-cell;
  position: relative;
}
.redesign-container {
  min-height: 100vh;
  padding: 0;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .redesign-container {
    width: 100%;
    flex-direction: column;
    overflow: hidden;
  }
}
.redesign-container .main-menu-redesign-container {
  flex: 0 0 17.5rem;
  z-index: 10;
}
.redesign-container .main-menu-redesign-container > div {
  height: 100%;
}
.redesign-container .main-menu-redesign-container .ec-tooltip-inner {
  text-align: left;
}
@media only screen and (max-width: 1280px) {
  .redesign-container .main-menu-redesign-container {
    flex: none;
  }
}
.redesign-container #content {
  margin: 0 auto;
  max-width: 1200px;
  flex-grow: 1;
}
@media only screen and (max-width: 1280px) {
  .redesign-container #content {
    width: 100%;
    padding-top: 96px;
  }
}
#main-menu-redesign {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  background-blend-mode: color;
  z-index: 1;
  position: fixed;
  overflow: hidden auto;
}
@media only screen and (min-width: 1281px) {
  #main-menu-redesign {
    width: 17.5rem;
    flex-direction: column;
    height: 100vh;
    padding: 24px;
    overflow-y: auto;
  }
}
#main-menu-redesign.main-menu-redesign--is-active {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
}
#main-menu-redesign.main-menu-redesign--is-active .main-menu-redesign__content {
  display: flex;
}
#main-menu-redesign.main-menu-redesign--is-active .main-menu-redesign__mobile-container {
  padding-bottom: 24px;
}
#main-menu-redesign hr {
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
}
#main-menu-redesign ul {
  margin-bottom: 0px;
  padding: 0;
  list-style: none;
  flex-grow: 1;
}
#main-menu-redesign a:hover,
#main-menu-redesign a:focus {
  text-decoration: none;
}
#main-menu-redesign .ec-ico {
  margin-right: 16px;
}
#main-menu-redesign .ec-menu-item {
  display: flex;
  padding: 8px;
}
#main-menu-redesign .main-menu-redesign__header__account {
  padding-top: 0;
  padding-bottom: 0;
}
#main-menu-redesign .main-menu-redesign__header__user-account {
  margin-bottom: 8px;
}
#main-menu-redesign .main-menu-redesign__header__user-account .ec-dropdown-reverse {
  text-align: left;
}
#main-menu-redesign .main-menu-redesign__header__user-account .ec-dropdown-reverse .ec-dropdown-toggle--menu-reverse {
  width: 100%;
  display: flex;
}
#main-menu-redesign .main-menu-redesign__header__user-account .ec-dropdown-reverse .ec-dropdown-toggle--menu-reverse:after {
  margin-right: 0;
  margin-left: auto;
}
@media only screen and (max-width: 1280px) {
  #main-menu-redesign .main-menu-redesign__header__user-account .ec-dropdown-menu {
    width: 100%;
  }
}
@media only screen and (min-width: 1281px) {
  #main-menu-redesign .main-menu-redesign__header__user-account .ec-dropdown-item {
    max-width: 232px;
  }
}
#main-menu-redesign .main-menu-redesign__header__user-account .ec-menu-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--ch-gray3);
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  padding-top: 0;
  margin: 0;
}
#main-menu-redesign .main-menu-redesign__header__user {
  flex-direction: row;
  display: flex;
  margin-bottom: 24px;
}
#main-menu-redesign .main-menu-redesign__header__user__letter-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0 none transparent;
  box-shadow: none;
  margin-right: 16px;
  padding: 0;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
#main-menu-redesign .main-menu-redesign__header__user__letter-icon__text {
  font-size: 14px;
  color: var(--ch-gray2);
}
#main-menu-redesign .main-menu-redesign__header__user__text {
  max-width: 100%;
  min-width: 0px;
}
#main-menu-redesign .main-menu-redesign__header__user__text span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 400;
}
#main-menu-redesign .main-menu-redesign__header__user:hover .main-menu-redesign__header__user__text span {
  color: var(--ch-gray2);
  font-weight: 700;
}
#main-menu-redesign .main-menu-redesign__header__user:hover .main-menu-redesign__header__user__letter-icon {
  background-color: var(--ch-gray2);
}
#main-menu-redesign .main-menu-redesign__header__user:hover .main-menu-redesign__header__user__letter-icon__text {
  color: var(--ch-gray7);
}
#main-menu-redesign .main-menu-redesign__logo {
  display: block;
}
#main-menu-redesign .main-menu-redesign__logo img {
  display: block;
  width: 88px;
}
#main-menu-redesign .main-menu-redesign__mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}
@media only screen and (min-width: 1281px) {
  #main-menu-redesign .main-menu-redesign__mobile-container {
    padding-top: 8px;
    padding-bottom: 24px;
  }
}
#main-menu-redesign .main-menu-redesign__navbar-toggle {
  padding: 0;
  border: none;
  line-height: 0;
}
#main-menu-redesign .main-menu-redesign__navbar-toggle:focus {
  outline: none;
}
#main-menu-redesign .main-menu-redesign__navbar-toggle i {
  height: 24px;
  width: 24px;
  margin: 0;
}
@media only screen and (min-width: 1281px) {
  #main-menu-redesign .main-menu-redesign__navbar-toggle {
    display: none;
  }
}
#main-menu-redesign .main-menu-redesign__main-delimiter {
  padding-bottom: 8px;
}
#main-menu-redesign .main-menu-redesign__content {
  display: none;
  flex-direction: column;
  flex-grow: 1;
}
@media screen and (min-width: 1281px) {
  #main-menu-redesign .main-menu-redesign__content {
    display: flex;
  }
}
#main-menu-redesign .main-menu-redesign__item {
  padding: 8px 0;
}
#main-menu-redesign .main-menu-redesign__item a {
  display: flex;
  margin-bottom: 0;
}
#main-menu-redesign .main-menu-redesign__item a .ec-menu-item-text {
  color: var(--ch-gray3);
  text-transform: capitalize;
  transition: none;
  font-weight: 400;
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-dashboard-reverse {
  background-image: var(--ch-ico-dashboard-reverse);
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-trades {
  background-image: var(--ch-ico-trades);
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-money {
  background-image: var(--ch-ico-money);
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-undo-arrow {
  background-image: var(--ch-ico-undo-arrow);
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-beneficiaries {
  background-image: var(--ch-ico-beneficiaries);
}
#main-menu-redesign .main-menu-redesign__item a .ec-ico-trending-up {
  background-image: var(--ch-ico-trending-up);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-menu-item-text,
#main-menu-redesign .main-menu-redesign__item a.active .ec-menu-item-text {
  color: var(--ch-gray2);
  font-weight: 700;
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-dashboard-reverse,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-dashboard-reverse {
  background-image: var(--ch-ico-dashboard-reverse-gray2);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-trades,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-trades {
  background-image: var(--ch-ico-trades-gray2);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-money,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-money {
  background-image: var(--ch-ico-money-gray2);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-undo-arrow,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-undo-arrow {
  background-image: var(--ch-ico-undo-arrow-gray2);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-beneficiaries,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-beneficiaries {
  background-image: var(--ch-ico-beneficiaries-gray2);
}
#main-menu-redesign .main-menu-redesign__item a:hover .ec-ico-trending-up,
#main-menu-redesign .main-menu-redesign__item a.active .ec-ico-trending-up {
  background-image: var(--ch-ico-trending-up-gray2);
}
#main-menu-redesign .main-menu-redesign__footer__links {
  display: flex;
  flex-direction: column;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item {
  margin-top: 8px;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item:first-child {
  margin-top: 16px;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item:last-child {
  margin-bottom: 0;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item .ec-ico {
  flex-shrink: 0;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item .ec-menu-item-text {
  color: var(--ch-gray3);
  text-transform: capitalize;
  transition: none;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item .ec-ico-info {
  background-image: var(--ch-ico-info-gray4);
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item .ec-ico-power-settings-new {
  background-image: var(--ch-ico-power-settings-new-gray4);
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item:hover .ec-menu-item-text {
  color: var(--ch-gray2);
  font-weight: 700;
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item:hover .ec-ico-info {
  background-image: var(--ch-ico-info-gray2);
}
#main-menu-redesign .main-menu-redesign__footer__links .ec-menu-item:hover .ec-ico-power-settings-new {
  background-image: var(--ch-ico-power-settings-new-gray2);
}
