@import "../../../less/global-vars";
@import "../../../less/mixins";

#content {
  padding: 1.5rem;
  background-color: white;
  display: table-cell;
  position: relative;
}

.container {
  min-height: 100vh;
  width: @container-width;
  padding: 0;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  .responsive(@breakpoint-sm, {
    width: 100%;
    min-width: 760px;
  });

  .main-menu-container {
    flex: 0 0 @main-menu-width;
    height: 100vh;
    display: flex;
    .responsive(@breakpoint-sm, {
      flex-basis: @main-menu-width-sm;
    });

    > div {
      flex-grow: 1;
    }

    .ec-tooltip-inner {
      text-align: left;
    }
  }

  #content {
    max-width: calc(~"1260px - "@main-menu-width);
    flex-grow: 1;
    .responsive(@breakpoint-sm, {
      max-width: none;
    });
  }
}

.ec .main-menu {
  width: @main-menu-width;
  height: 100vh;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-blend-mode: color;
  z-index: 1;

  ul {
    padding: 0;
    list-style: none;
    flex-grow: 1;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  .main-menu__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .main-menu__header__logo {
      margin-bottom: 23px;

      img {
        height: 2.5rem;
      }
    }

    .main-menu__header__avatar {
      width: 3rem;
      height: 3rem;
      margin-bottom: 0.5rem;
      border-radius: 0.35rem;
      pointer-events: none;
    }

    .main-menu__header__user-account {
      display: flex;
      flex-direction: column;
      align-items: center;
      white-space: nowrap;
      transition: 0.5s;
      width: 100%;
      text-align: center;
    }

    .main-menu__header__user {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .main-menu__header__account-selector {
      width: 100%;

      a {
        display: block;
        pointer-events: none;
        .title {
          pointer-events: auto;
          max-width: 13.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
      a::after {
        pointer-events: auto;
        vertical-align: top;
      }
    }

    .main-menu__header__account {
      max-width: 100%;
    }
  }

  .main-menu__button {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;

    .main-menu__button__tooltip {
      max-width: 100%;
    }

    .ec-btn {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ec-btn-sm {
      display: none;
    }
  }

  .main-menu__footer {

    .main-menu__footer__links {
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;

      > a {
        height: 1.5rem;
        margin: 0 0.5rem;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        transition: 0.5s;
        max-width: 50%;
        overflow: hidden;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .text {
        transition: color 0.25s;
        white-space: nowrap;
        flex: 1 1 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .ec-ico {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .main-menu__footer__copyright {
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ec-dropdown-menu {
    max-width: 100%;
  }
}

@media only screen and (max-width: @breakpoint-sm) {
  .ec .main-menu {
    width: @main-menu-width-sm;
    transition: width 0.5s;
    z-index: 1060; // For being over tooltips that appears in the content

    .main-menu__header {
      margin: -0.5rem -0.5rem 2.5rem;
      padding-bottom: 0;
      flex-direction: row;

      .main-menu__header__logo {
        display: none;
      }

      .main-menu__header__avatar {
        margin-bottom: 0;
        pointer-events: auto;
        cursor: pointer;
      }

      .main-menu__header__user-account {
        opacity: 0;
        margin-left: 1rem;
        align-items: flex-start;
        text-align: left;
        min-width: 0;
      }
    }

    .main-menu__button {
      .ec-btn:not(.ec-btn-ico) {
        display: none;
      }

      .ec-btn-ico {
        display: inline-block;
      }
    }

    .main-menu__footer {
      .main-menu__footer__links {
        flex-direction: column-reverse;
        margin-bottom: 0.5rem;
        align-items: center;

        > a {
          margin: 1.5rem 0 0;
          width: 1.5rem;
          max-width: none;
        }

        .text {
          display: none;
        }

        .ec-ico {
          margin: 0;
        }
      }

      .main-menu__footer__copyright {
        display: none;
      }
    }

    &.main-menu--expanded {
      width: @main-menu-width-sm-expanded;

      .main-menu__header {
        .main-menu__header__user-account {
          opacity: 1;
        }
      }

      .main-menu__button {
        .ec-btn-sm:not(.ec-btn-ico) {
          display: block;
        }

        .ec-btn-ico {
          display: none;
        }
      }

      .main-menu__footer {
        .main-menu__footer__links {
          flex-direction: row;
          margin-bottom: 1rem;

          > a {
            margin: 0 0.5rem;
            width: auto;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          .text {
            display: block;
          }

          .ec-ico {
            margin-right: 0.5rem;
          }
        }

        .main-menu__footer__copyright {
          display: block;
        }
      }
    }
  }
}

.ec .main-menu .main-menu__button .main-menu__convert-and-pay-link {
  text-overflow: clip;
}
