.ec {
  label {
    line-height: 1.5;
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
  }

  .ui-datepicker:before {
    border-color: transparent transparent transparent;
  }

  .ec-form-control::placeholder {
    font-style: normal;
  }
}