#content {
  padding: 1.5rem;
  background-color: white;
  display: table-cell;
  position: relative;
}
.container {
  min-height: 100vh;
  width: 1260px;
  padding: 0;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 1279px) {
  .container {
    width: 100%;
    min-width: 760px;
  }
}
.container .main-menu-container {
  flex: 0 0 17.5rem;
  height: 100vh;
  display: flex;
}
@media only screen and (max-width: 1279px) {
  .container .main-menu-container {
    flex-basis: 5rem;
  }
}
.container .main-menu-container > div {
  flex-grow: 1;
}
.container .main-menu-container .ec-tooltip-inner {
  text-align: left;
}
.container #content {
  max-width: calc(1260px -  17.5rem);
  flex-grow: 1;
}
@media only screen and (max-width: 1279px) {
  .container #content {
    max-width: none;
  }
}
.ec .main-menu {
  width: 17.5rem;
  height: 100vh;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-blend-mode: color;
  z-index: 1;
}
.ec .main-menu ul {
  padding: 0;
  list-style: none;
  flex-grow: 1;
}
.ec .main-menu a:hover,
.ec .main-menu a:focus {
  text-decoration: none;
}
.ec .main-menu .main-menu__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}
.ec .main-menu .main-menu__header .main-menu__header__logo {
  margin-bottom: 23px;
}
.ec .main-menu .main-menu__header .main-menu__header__logo img {
  height: 2.5rem;
}
.ec .main-menu .main-menu__header .main-menu__header__avatar {
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  border-radius: 0.35rem;
  pointer-events: none;
}
.ec .main-menu .main-menu__header .main-menu__header__user-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  transition: 0.5s;
  width: 100%;
  text-align: center;
}
.ec .main-menu .main-menu__header .main-menu__header__user {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ec .main-menu .main-menu__header .main-menu__header__account-selector {
  width: 100%;
}
.ec .main-menu .main-menu__header .main-menu__header__account-selector a {
  display: block;
  pointer-events: none;
}
.ec .main-menu .main-menu__header .main-menu__header__account-selector a .title {
  pointer-events: auto;
  max-width: 13.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.ec .main-menu .main-menu__header .main-menu__header__account-selector a::after {
  pointer-events: auto;
  vertical-align: top;
}
.ec .main-menu .main-menu__header .main-menu__header__account {
  max-width: 100%;
}
.ec .main-menu .main-menu__button {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.ec .main-menu .main-menu__button .main-menu__button__tooltip {
  max-width: 100%;
}
.ec .main-menu .main-menu__button .ec-btn {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ec .main-menu .main-menu__button .ec-btn-sm {
  display: none;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links > a {
  height: 1.5rem;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  transition: 0.5s;
  max-width: 50%;
  overflow: hidden;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links > a:first-child {
  margin-left: 0;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links > a:last-child {
  margin-right: 0;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links .text {
  transition: color 0.25s;
  white-space: nowrap;
  flex: 1 1 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ec .main-menu .main-menu__footer .main-menu__footer__links .ec-ico {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
.ec .main-menu .main-menu__footer .main-menu__footer__copyright {
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ec .main-menu .ec-dropdown-menu {
  max-width: 100%;
}
@media only screen and (max-width: 1279px) {
  .ec .main-menu {
    width: 5rem;
    transition: width 0.5s;
    z-index: 1060;
  }
  .ec .main-menu .main-menu__header {
    margin: -0.5rem -0.5rem 2.5rem;
    padding-bottom: 0;
    flex-direction: row;
  }
  .ec .main-menu .main-menu__header .main-menu__header__logo {
    display: none;
  }
  .ec .main-menu .main-menu__header .main-menu__header__avatar {
    margin-bottom: 0;
    pointer-events: auto;
    cursor: pointer;
  }
  .ec .main-menu .main-menu__header .main-menu__header__user-account {
    opacity: 0;
    margin-left: 1rem;
    align-items: flex-start;
    text-align: left;
    min-width: 0;
  }
  .ec .main-menu .main-menu__button .ec-btn:not(.ec-btn-ico) {
    display: none;
  }
  .ec .main-menu .main-menu__button .ec-btn-ico {
    display: inline-block;
  }
  .ec .main-menu .main-menu__footer .main-menu__footer__links {
    flex-direction: column-reverse;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  .ec .main-menu .main-menu__footer .main-menu__footer__links > a {
    margin: 1.5rem 0 0;
    width: 1.5rem;
    max-width: none;
  }
  .ec .main-menu .main-menu__footer .main-menu__footer__links .text {
    display: none;
  }
  .ec .main-menu .main-menu__footer .main-menu__footer__links .ec-ico {
    margin: 0;
  }
  .ec .main-menu .main-menu__footer .main-menu__footer__copyright {
    display: none;
  }
  .ec .main-menu.main-menu--expanded {
    width: 16rem;
  }
  .ec .main-menu.main-menu--expanded .main-menu__header .main-menu__header__user-account {
    opacity: 1;
  }
  .ec .main-menu.main-menu--expanded .main-menu__button .ec-btn-sm:not(.ec-btn-ico) {
    display: block;
  }
  .ec .main-menu.main-menu--expanded .main-menu__button .ec-btn-ico {
    display: none;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links {
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links > a {
    margin: 0 0.5rem;
    width: auto;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links > a:first-child {
    margin-left: 0;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links > a:last-child {
    margin-right: 0;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links .text {
    display: block;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__links .ec-ico {
    margin-right: 0.5rem;
  }
  .ec .main-menu.main-menu--expanded .main-menu__footer .main-menu__footer__copyright {
    display: block;
  }
}
.ec .main-menu .main-menu__button .main-menu__convert-and-pay-link {
  text-overflow: clip;
}
