@import "../../../less/global-vars";

.ec .main-menu__item .ec-menu-item {
  height: 3rem;
  padding: 0 1.5rem 0 1.5rem;
  margin: 0 -1.5rem 0 -1.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  > * {
    flex-shrink: 0;
  }

  .ec-ico {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    transition: 0.5s;
  }

  .ec-menu-item-text {
    opacity: 1;
    transition: 0.5s;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.active {
    pointer-events: none;
  }
}

@media only screen and (max-width: @breakpoint-sm) {
  .ec .main-menu:not(.main-menu--expanded) {
    .main-menu__item .ec-menu-item {
      .ec-ico {
        margin-right: 0;
        margin-left: 0.25rem;
      }

      .ec-menu-item-text {
        opacity: 0;
      }
    }
  }
}
