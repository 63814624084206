@import "../../../less/global-vars";
@import "../../../less/mixins";

// The following CSS is nothing to getting inspiration from.
// Due to legacy code particularities we forced CSS to do things we are not proud of like using IDs and other anti-patterns.
// This is legacy code though and should be deleted when the migration to new stack is complete.
// For inspiration on how CSS should be properly written, have a look at the new stack frontend.
// In short, on the new stack ALWAYS use a mobile first approach, with ITCSS and BEM as described thoroughly in our documentation!
// This means use of max-width in media queries, IDs, nesting elements etc. is strictly forbidden!
// If you want to amend other legacy pages and CSS gives you a hard time nest everything behind a unique ID so you will not pollute all the CSS of the legacy.

#content {
  padding: 1.5rem;
  background-color: white;
  display: table-cell;
  position: relative;
}

.redesign-container {
  min-height: 100vh;
  padding: 0;
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;

  @media only screen and (max-width: 1280px) {
    width: 100%;
    flex-direction: column;
    overflow: hidden;
  }

  .main-menu-redesign-container {
    flex: 0 0 @main-menu-width;
    z-index: 10;

    > div {
      height: 100%;
    }

    .ec-tooltip-inner {
      text-align: left;
    }

    @media only screen and (max-width: 1280px) {
      flex: none;
    }
  }

  #content {
    margin: 0 auto;
    max-width: @main-container-content-max-width;
    flex-grow: 1;

    @media only screen and (max-width: 1280px) {
      width: 100%;
      padding-top: 96px; // 16px top and bottom padding + 40px logo height + 24px top padding
    }
  }
}

#main-menu-redesign {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  background-blend-mode: color;
  z-index: 1;
  position: fixed;
  overflow: hidden auto;

  @media only screen and (min-width: 1281px) {
    width: @main-menu-width;
    flex-direction: column;
    height: 100vh;
    padding: 24px;
    overflow-y: auto;
  }

  &.main-menu-redesign--is-active {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    flex-direction: column;

    .main-menu-redesign {
      &__content {
        display: flex;
      }

      &__mobile-container {
        padding-bottom: 24px;
      }
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-bottom: 0px;
    padding: 0;
    list-style: none;
    flex-grow: 1;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }

  .ec-ico {
    margin-right: 16px;
  }

  .ec-menu-item {
    display: flex;
    padding: 8px;
  }

  .main-menu-redesign {
    &__header {
      &__account {
        padding-top: 0;
        padding-bottom : 0;
      }

      &__user-account {
        margin-bottom: 8px;

        .ec-dropdown-reverse {
          text-align: left;

          .ec-dropdown-toggle--menu-reverse {
            width: 100%;
            display: flex;

            &:after {
              margin-right: 0;
              margin-left: auto;
            }
          }
        }

        @media only screen and (max-width: 1280px) {
          .ec-dropdown-menu {
            width: 100%;
          }
        }


        @media only screen and (min-width: 1281px) {
          .ec-dropdown-item {
            max-width: 232px;
          }
        }

        .ec-menu-item-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--ch-gray3);
          font-size: 18px;
          line-height: 24px;
          font-weight: 300;
          padding-top: 0;
          margin: 0;
        }
      }

      &__user {
        flex-direction: row;
        display: flex;

        margin-bottom: 24px;

        &__letter-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 0 none transparent;
          box-shadow: none;
          margin-right: 16px;
          padding: 0;
          width: 24px;
          height: 24px;
          flex-shrink: 0;

          &__text {
            font-size: 14px;
            color: var(--ch-gray2);
          }
        }

        &__text {
          max-width: 100%;
          min-width: 0px;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            text-transform: capitalize;
            margin-bottom: 0;
            font-weight: 400;
          }
        }

        &:hover {
          .main-menu-redesign__header__user__text span {
            color: var(--ch-gray2);
            font-weight: 700;
          }

          .main-menu-redesign__header__user__letter-icon {
            background-color: var(--ch-gray2);
          }

          .main-menu-redesign__header__user__letter-icon__text {
            color: var(--ch-gray7);
          }
        }
      }
    }

    &__logo {
      display: block;

      img {
        display: block;
        width: 88px;
      }
    }

    &__mobile-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 8px;
      padding-right: 8px;

      @media only screen and (min-width: 1281px) {
        padding-top: 8px;
        padding-bottom: 24px;
      }
    }

    &__navbar-toggle {
      padding: 0;
      border: none;
      line-height: 0;
  
      &:focus {
        outline: none;
      }
  
      i {
        height: 24px;
        width: 24px;
        margin: 0;
      }
  
      @media only screen and (min-width: 1281px) {
        display: none;
      }
    }

    &__main-delimiter {
      padding-bottom: 8px;
    }

    &__content {
      display: none;
      flex-direction: column;
      flex-grow: 1;

      @media screen and (min-width: 1281px) {
        display: flex;
      }
    }

    &__item {
      padding: 8px 0;

      a {
        display: flex;
        margin-bottom: 0;

        .ec-menu-item-text {
          color: var(--ch-gray3);
          text-transform: capitalize;
          transition: none;
          font-weight: 400;
        }

        .ec-ico-dashboard-reverse {
          background-image: var(--ch-ico-dashboard-reverse);
        }

        .ec-ico-trades {
          background-image: var(--ch-ico-trades);
        }

        .ec-ico-money {
          background-image: var(--ch-ico-money);
        }

        .ec-ico-undo-arrow {
          background-image: var(--ch-ico-undo-arrow);
        }

        .ec-ico-beneficiaries {
          background-image: var(--ch-ico-beneficiaries);
        }

        .ec-ico-trending-up {
          background-image: var(--ch-ico-trending-up);
        }

        &:hover,
        &.active {
          .ec-menu-item-text {
            color: var(--ch-gray2);
            font-weight: 700;
          }

          .ec-ico-dashboard-reverse {
            background-image: var(--ch-ico-dashboard-reverse-gray2);
          }

          .ec-ico-trades {
            background-image: var(--ch-ico-trades-gray2);
          }

          .ec-ico-money {
            background-image: var(--ch-ico-money-gray2);
          }

          .ec-ico-undo-arrow {
            background-image: var(--ch-ico-undo-arrow-gray2);
          }

          .ec-ico-beneficiaries {
            background-image: var(--ch-ico-beneficiaries-gray2);
          }

          .ec-ico-trending-up {
            background-image: var(--ch-ico-trending-up-gray2);
          }
        }
      }
    }

    &__footer__links {
      display: flex;
      flex-direction: column;

      .ec-menu-item {
        margin-top: 8px;

        // We might have 1 or more items in the footer i.e. help button is not available in EMP.
        &:first-child {
          margin-top: 16px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .ec-ico {
          flex-shrink: 0;
        }

        .ec-menu-item-text {
          color: var(--ch-gray3);
          text-transform: capitalize;
          transition: none;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }

        .ec-ico-info {
          background-image: var(--ch-ico-info-gray4);
        }

        .ec-ico-power-settings-new {
          background-image: var(--ch-ico-power-settings-new-gray4);
        }

        &:hover {
          .ec-menu-item-text {
            color: var(--ch-gray2);
            font-weight: 700;
          }

          .ec-ico-info {
            background-image: var(--ch-ico-info-gray2);
          }

          .ec-ico-power-settings-new {
            background-image: var(--ch-ico-power-settings-new-gray2);
          }
        }
      }
    }
  }
}
