.skeleton {
  .skeleton__element {
    height: 2rem;
    margin: 0 2rem 2.5rem 2rem;
    border-radius: 5px;

    &.skeleton__element--title {
      max-width: 10rem;

      &:not(:first-child) {
        margin-top: 4rem;
      }
    }

    &.skeleton__element--small {
      height: 1rem;
    }

    &.skeleton__element__tbl-header {
      width: 55%;
      height: 1rem;
      margin: 0 2rem .5rem 0;
    }

    &.skeleton__element__tbl-row {
      width: 90%;
      height: 1rem;
      margin: 0 2rem .5rem 0;
    }

    &.skeleton__element__button {
      width: 40%;
      border-radius: 3rem;
      margin: 0 auto;
    }

    &.skeleton__element__checkbox {
      width: 1rem;
      height: 1rem;
      margin: 0 .5rem;
    }
  }
}