@charset "UTF-8";

@font-face {
  font-family: "it-icons";
  src:url("../fonts/it-fonts/it-icons.eot");
  src:url("../fonts/it-fonts/it-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/it-fonts/it-icons.woff") format("woff"),
    url("../fonts/it-fonts/it-icons.ttf") format("truetype"),
    url("../fonts/it-fonts/it-icons.svg#it-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "it-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "it-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "a";
}
.icon-clock:before {
  content: "b";
}
.icon-cloud-upload:before {
  content: "c";
}
.icon-edit:before {
  content: "d";
}
.icon-engine:before {
  content: "g";
}
.icon-export:before {
  content: "h";
}
.icon-folder:before {
  content: "i";
}
.icon-folder-not-found:before {
  content: "j";
}
.icon-folder-update:before {
  content: "k";
}
.icon-help:before {
  content: "l";
}
.icon-home:before {
  content: "m";
}
.icon-key:before {
  content: "n";
}
.icon-lock:before {
  content: "o";
}
.icon-meeting:before {
  content: "p";
}
.icon-mobile:before {
  content: "q";
}
.icon-ok:before {
  content: "r";
}
.icon-plus:before {
  content: "s";
}
.icon-power:before {
  content: "t";
}
.icon-refresh:before {
  content: "u";
}
.icon-search:before {
  content: "v";
}
.icon-ticket:before {
  content: "w";
}
.icon-transfer:before {
  content: "x";
}
.icon-trash:before {
  content: "y";
}
.icon-upload:before {
  content: "z";
}
.icon-user:before {
  content: "A";
}
.icon-user-group:before {
  content: "B";
}
.icon-wrench:before {
  content: "C";
}
.icon-edit-list:before {
  content: "e";
}
