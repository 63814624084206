.skeleton .skeleton__element {
  height: 2rem;
  margin: 0 2rem 2.5rem 2rem;
  border-radius: 5px;
}
.skeleton .skeleton__element.skeleton__element--title {
  max-width: 10rem;
}
.skeleton .skeleton__element.skeleton__element--title:not(:first-child) {
  margin-top: 4rem;
}
.skeleton .skeleton__element.skeleton__element--small {
  height: 1rem;
}
.skeleton .skeleton__element.skeleton__element__tbl-header {
  width: 55%;
  height: 1rem;
  margin: 0 2rem 0.5rem 0;
}
.skeleton .skeleton__element.skeleton__element__tbl-row {
  width: 90%;
  height: 1rem;
  margin: 0 2rem 0.5rem 0;
}
.skeleton .skeleton__element.skeleton__element__button {
  width: 40%;
  border-radius: 3rem;
  margin: 0 auto;
}
.skeleton .skeleton__element.skeleton__element__checkbox {
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
}
