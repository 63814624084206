@import "global-vars";

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.hide-sm() {
  .responsive(@breakpoint-sm, {
    display: none;
  })
}